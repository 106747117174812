import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { Settings } from '@common/core/config/settings.service';
import { Subscription } from 'rxjs';
import { HomepageContent } from './homepage-content';
import { DomSanitizer } from '@angular/platform-browser';
import anime from 'animejs/lib/anime.es';

@Component({
    selector: 'homepage',
    templateUrl: './homepage.component.html',
    styleUrls: ['./homepage.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomepageComponent implements OnInit, OnDestroy {
    public content: HomepageContent;
    public overlayBackground;
    public images = [
        { path: 'https://s3.ap-east-1.amazonaws.com/cloud.mn/image0.png' },
        { path: 'https://s3.ap-east-1.amazonaws.com/cloud.mn/image1.png' },
        { path: 'https://s3.ap-east-1.amazonaws.com/cloud.mn/image2.png' },
        { path: 'https://s3.ap-east-1.amazonaws.com/cloud.mn/image3.png' },
        { path: 'https://s3.ap-east-1.amazonaws.com/cloud.mn/image4.png' },
        { path: 'https://s3.ap-east-1.amazonaws.com/cloud.mn/image5.png' },
        { path: 'https://s3.ap-east-1.amazonaws.com/cloud.mn/image6.png' },
    ];
    private sub: Subscription;

    constructor(
        public settings: Settings,
        private cd: ChangeDetectorRef,
        private sanitizer: DomSanitizer
    ) {}

    ngOnInit() {
        this.sub = this.settings.all$().subscribe(() => {
            this.content = this.settings.getJson('homepage.appearance');
            this.overlayBackground = this.sanitizer.bypassSecurityTrustStyle(
                `linear-gradient(45deg, ${this?.content?.headerOverlayColor1} 0%, ${this?.content?.headerOverlayColor2} 100%)`
            );
            this.cd.markForCheck();
        });

        var a = anime.path('#path_1');
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    public scrollToFeatures() {
        document
            .querySelector('.inline-features')
            .scrollIntoView({ block: 'start', inline: 'center', behavior: 'smooth' });
    }
}
