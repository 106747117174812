import { NgModule } from '@angular/core';
import { AuthRoutingModule } from './auth.routing';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { BusinessRegisterComponent } from './business-register/business-register.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { RequestExtraCredentialsModalComponent } from './request-extra-credentials-modal/request-extra-credentials-modal.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslationsModule } from '@common/core/translations/translations.module';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { LoadingIndicatorModule } from '@common/core/ui/loading-indicator/loading-indicator.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CustomMenuModule } from '@common/core/ui/custom-menu/custom-menu.module';
import { AuthPageComponent } from './auth-page/auth-page.component';

@NgModule({
    imports: [
        AuthRoutingModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        LoadingIndicatorModule,
        TranslationsModule,
        CustomMenuModule,

        // material
        MatDialogModule,
        MatIconModule,
        MatButtonModule,
        MatTooltipModule,
        MatCheckboxModule,
    ],
    declarations: [
        LoginComponent,
        RegisterComponent,
        BusinessRegisterComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        RequestExtraCredentialsModalComponent,
        AuthPageComponent,
    ],
})
export class AuthModule {}
