import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { EmailSettingsResolve } from './email-settings-resolve.service';
import { EmailSettingsRoutingModule } from './email-settings-routing.module';
import { DatatableModule } from '../datatable/datatable.module';
import { UploadImageControlModule } from '@common/shared/form-controls/upload-image-control/upload-image-control.module';
import { EmailSettingsComponent } from './email-settings.component';
import { MaterialNavbarModule } from '@common/core/ui/material-navbar/material-navbar.module';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { TranslationsModule } from '@common/core/translations/translations.module';
import { NoResultsMessageModule } from '@common/core/ui/no-results-message/no-results-message.module';
import { FormatPipesModule } from '@common/core/ui/format-pipes/format-pipes.module';
import { MatTabsModule } from '@angular/material/tabs';
import { EmailUserIndexComponent } from '@common/email-settings/users/user-index.component';
import { CrupdateEmailUserModalComponent } from '@common/email-settings/users/crupdate-user-modal/crupdate-user-modal.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialNavbarModule,
        EmailSettingsRoutingModule,
        TranslationsModule,
        NoResultsMessageModule,
        MatProgressBarModule,
        MatTooltipModule,
        FormatPipesModule,
        DatatableModule,
        MatDialogModule,
        UploadImageControlModule,
        // material
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatTabsModule,
    ],
    declarations: [
        EmailSettingsComponent,
        EmailUserIndexComponent,
        CrupdateEmailUserModalComponent,
    ],
    exports: [EmailSettingsRoutingModule, DatatableModule],
    providers: [EmailSettingsResolve],
})
export class EmailSettingsModule {}
