<material-navbar
    [menuPosition]="settings.get('vebto.navbar.defaultPosition')"
    *ngIf="!settings.get('vebto.accountSettings.hideNavbar')"
></material-navbar>

<div class="content container" *ngIf="userErrors$ | async as userErrors">
    <h1 class="header">
        <span class="header-text" trans>Email Settings</span>
        <ng-container *ngIf="breakpoints.isMobile$ | async">
            <button
                mat-icon-button
                [matMenuTriggerFor]="navSidebarMenu"
                class="mobile-toggle-button"
            >
                <mat-icon svgIcon="settings"></mat-icon>
            </button>
            <mat-menu #navSidebarMenu="matMenu">
                <logged-in-user-menu></logged-in-user-menu>
            </mat-menu>
        </ng-container>
    </h1>

    <div class="panels">
        <mat-tab-group>
            <mat-tab label="Overview">
                <mat-progress-bar
                    class="loading-bar"
                    mode="indeterminate"
                    color="accent"
                    *ngIf="loading$ | async"
                ></mat-progress-bar>
                <iframe
                    class="dns_check"
                    id="dns_check"
                    [frameBorder]="0"
                    (load)="loaded()"
                    [class.d-none]="loading$"
                ></iframe>
            </mat-tab>
            <mat-tab label="Members">
                <email-user-index [workspaceInput]="workspace$ | async"></email-user-index
            ></mat-tab>
        </mat-tab-group>

        <ng-template #extraPanelRef></ng-template>
    </div>
</div>
