import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard } from '../guards/auth-guard.service';
import { EmailSettingsComponent } from './email-settings.component';
import { EmailSettingsResolve } from './email-settings-resolve.service';
import { CheckPermissionsGuard } from '@common/guards/check-permissions-guard.service';

const routes: Routes = [
    // TODO: reloading if already in "account/settings" ads "settings" again to url if uncommented
    // {
    //     path: 'account',
    //     pathMatch: 'full',
    //     redirectTo: 'account/settings'
    // },
    {
        path: 'email/settings',
        component: EmailSettingsComponent,
        resolve: { api: EmailSettingsResolve },
        canActivate: [AuthGuard, CheckPermissionsGuard],
        data: { name: 'account-email' },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class EmailSettingsRoutingModule {}
