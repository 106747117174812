<ng-container
    *ngIf="
        (currentUser.isLoggedIn$ | async) || (breakpoints.isMobile$ | async);
        else authButtons
    "
>
    <div class="menu-trigger" [matMenuTriggerFor]="menu">
        <img class="avatar" [src]="currentUser.get('avatar')" />

        <div class="email">{{ currentUser.get('email') }}</div>
        <mat-icon svgIcon="arrow-drop-down" class="dropdown-icon"></mat-icon>

        <button
            mat-icon-button
            class="mobile-toggle"
            aria-label="Show logged in user options"
        >
            <mat-icon svgIcon="person"></mat-icon>
        </button>
    </div>

    <mat-menu #menu="matMenu" [overlapTrigger]="false" class="logged-in-user-widget-menu">
        <logged-in-user-menu></logged-in-user-menu>
    </mat-menu>
</ng-container>

<ng-template #authButtons>
    <!-- <a
        routerLink="/pages/2/terms-of-service"
        mat-raised-button
        color="accent"
        class="login-btn"
        trans
        >Billing</a
    > -->
    <a
        routerLink="/contact"
        mat-raised-button
        color="accent"
        class="login-btn"
        trans
        >Contact us</a
    >
    <a
        *ngIf="!config.get('registration.disable') && !hideRegisterButton"
        class="register-btn"
        routerLink="/login"
        color="accent"
        mat-flat-button
        trans
        >Login</a
    >
    <!-- <a routerLink="/login" mat-raised-button color="accent" class="login-btn" trans
        >Login</a
    > -->
</ng-template>
