<auth-page infoRowTarget="signin">
    <form
        (ngSubmit)="getStep() ? next() : register()"
        *ngIf="errors$ | async as errors"
        [formGroup]="form"
        ngNativeValidate
    >
        <a class="logo" [routerLink]="['/']"><img [src]="logoUrl()" alt="logo" /></a>
        <div class="many-inputs" *ngIf="getStep()">
            <div class="input-container">
                <label for="business_name" trans>Business name</label>
                <input
                    type="text"
                    formControlName="business_name"
                    id="business_name"
                    required
                />
                <p class="error" *ngIf="errors.business_name">
                    <mat-icon svgIcon="warning"></mat-icon>
                    <span>{{ errors.business_name }}</span>
                </p>
            </div>
            <div class="input-container">
                <label for="domain" trans>Domain</label>
                <input type="text" formControlName="domain" id="domain" required />
                <p class="error" *ngIf="errors.domain">
                    <mat-icon svgIcon="warning"></mat-icon>
                    <span>{{ errors.domain }}</span>
                </p>
            </div>
        </div>
        <div class="many-inputs" *ngIf="!getStep()">
            <div class="input-container">
                <label for="email" trans>Email</label>
                <input type="email" formControlName="email" id="email" required />
                <p class="error" *ngIf="errors.email">
                    <mat-icon svgIcon="warning"></mat-icon>
                    <span>{{ errors.email }}</span>
                </p>
            </div>

            <div class="input-container">
                <label for="register_password" trans>Password</label>
                <div class="password-input-container">
                    <input
                        type="password"
                        formControlName="password"
                        id="register_password"
                        required
                    />
                    <mat-icon svgIcon="eye"></mat-icon>
                </div>
                <p class="error" *ngIf="errors.password">
                    <mat-icon svgIcon="warning"></mat-icon>
                    <span>{{ errors.password }}</span>
                </p>
            </div>

            <div class="input-container">
                <label for="register_password_confirmation" trans>Confirm Password</label>
                <input
                    type="password"
                    formControlName="password_confirmation"
                    id="register_password_confirmation"
                    required
                />
            </div>
        </div>

        <div class="row">
            <button
                mat-raised-button
                [routerLink]="['/login']"
                class="primary-button secondary-button"
                color="white"
                type="button"
                trans
            >
                Sign In
            </button>
            <button
                mat-raised-button
                *ngIf="!getStep()"
                class="primary-button"
                color="white"
                type="submit"
                trans
            >
                Create Account
            </button>
            <button
                mat-raised-button
                *ngIf="getStep()"
                class="primary-button"
                color="accent"
                trans
            >
                Next
            </button>
        </div>
    </form>
</auth-page>
