import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import { CrupdateEmailUserModalComponent } from './crupdate-user-modal/crupdate-user-modal.component';
import { User } from '@common/core/types/models/User';
import { Users } from '@common/auth/users.service';
import { CurrentUser } from '@common/auth/current-user';
import { Settings } from '@common/core/config/settings.service';
import { Toast } from '@common/core/ui/toast.service';
import { HttpErrors } from '@common/core/http/errors/http-errors.enum';
import { Role } from '@common/core/types/models/Role';
import { BackendErrorResponse } from '@common/core/types/backend-error-response';
import { DatatableService } from '../../datatable/datatable.service';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { Workspace } from '../../workspaces/types/workspace';
import { CsvExporterService } from '@common/csv/csv-exporter.service';
import { USER_INDEX_FILTERS } from '@common/admin/users/user-index-filters';
import { WorkspaceMember } from '@common/workspaces/types/workspace-member';
@Component({
    selector: 'email-user-index',
    templateUrl: './user-index.component.html',
    styleUrls: ['./user-index.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [DatatableService],
})
export class EmailUserIndexComponent implements OnDestroy {
    public users$ = new BehaviorSubject<WorkspaceMember[]>([]);
    public workspace$ = new BehaviorSubject<Workspace>({} as Workspace);
    public loading$ = new BehaviorSubject<boolean>(true);
    filters = USER_INDEX_FILTERS;
    @Input()
    set workspaceInput(value: Workspace) {
        if (value.members) {
            console.log(value);
            this.workspace$.next(value);
            this.users$.next(value.members);
            this.loading$.next(false);
        }
    }
    constructor(
        private userService: Users,
        public currentUser: CurrentUser,
        public settings: Settings,
        private toast: Toast,
        public datatable: DatatableService<User>,
        private csv: CsvExporterService
    ) {}

    ngOnDestroy() {
        this.datatable.destroy();
    }

    public makeRolesList(roles: Role[]): string {
        return roles
            .slice(0, 3)
            .map((role) => role.name)
            .join(', ');
    }

    public maybeDeleteSelectedUsers() {
        this.datatable.confirmResourceDeletion('users').subscribe(() => {
            this.userService.delete(this.datatable.selectedRows$.value).subscribe(
                () => {
                    this.datatable.reset();
                    this.toast.open('Deleted selected users');
                },
                (errResponse: BackendErrorResponse) => {
                    this.toast.open(errResponse.message || HttpErrors.Default);
                }
            );
        });
    }

    public showCrupdateUserModal(user?: User) {
        this.datatable
            .openCrupdateResourceModal(CrupdateEmailUserModalComponent, {
                user,
                workspace: this.workspace$,
            })
            .subscribe();
    }

    exportCsv() {
        this.csv.export(Users.EXPORT_CSV_URI);
    }
}
